import { Box, ChakraProvider, extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import React from "react";
import { Route, Routes } from "react-router-dom";
import colors from "./assets/json/colors.json";
import NavBar from "./components/NavBar";
import Contact from "./pages/Contact";
import Home from "./pages/Home";

const theme = extendTheme({
    styles: {
        global: (props) => ({
            body: {
                font: "Lato",
                bg: mode("#EDF2F7", "#2D3748")(props),
                color: mode("#2D3748", "#E2E8F0")(props),
                lineHeight: "base",
            },
        }),
    },
    fonts: {
        body: "Lato",
        heading: "Lato",
    },
    colors,
});

function App() {
    return (
        <ChakraProvider theme={theme}>
            <Box>
                <NavBar />
                <Routes>
                    <Route
                        path="/"
                        element={<Home />}
                    />
                    <Route
                        path="contact"
                        element={<Contact />}
                    />
                </Routes>
            </Box>
        </ChakraProvider>
    );
}

export default App;
