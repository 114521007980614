import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Card, CardBody, Container, Flex, Heading, Image, Link, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
const home = require("../assets/images/home.jpg");
const about = require("../assets/images/about.jpg");

function Home() {
    return (
        <Box pb={6}>
            <Box mb={6} pb={24} bg={useColorModeValue("light.bg.highlighted", "dark.bg.highlighted")} clipPath="polygon(100% 0, 100% 100%, 50% 92%, 0 100%, 0 0)">
                <Container maxW="container.lg">
                    <Flex direction={"column"} align={"center"}>
                        <Image src={home} />
                        <Heading
                            as="h1"
                            textAlign={"center"}
                            lineHeight={1.2}
                            fontSize={{ base: "5xl", md: "6xl", lg: "7xl" }}
                            px={8}
                            fontWeight={700}
                            mt={6}
                            color={useColorModeValue("black", "white")}
                        >
                            श्री कृष्ण प्रणामी सेवा कुञ्ज
                        </Heading>
                    </Flex>
                </Container>
            </Box>
            <Container maxW="container.lg">
                <Card mb={6} direction="row" overflow="hidden" variant="outline">
                    <Image objectFit="cover" maxW="200px" src={about} alt="डॉ तिलक चैतन्य" />

                    <Stack>
                        <CardBody>
                            <Heading size="md">संस्थापक</Heading>
                            <Text py="2">डॉ तिलक चैतन्य</Text>
                        </CardBody>
                    </Stack>
                </Card>
                <Link href="https://firebasestorage.googleapis.com/v0/b/sewakunj-99012.appspot.com/o/paramdham.pdf?alt=media" isExternal>
                    <Heading as="h2" fontSize={{ base: "2xl" }} fontWeight={700} color={useColorModeValue("black", "white")}>
                        परमधाम चिंतन
                        <ExternalLinkIcon mx={2} mb={1} />
                    </Heading>
                </Link>
            </Container>
        </Box>
    );
}

export default Home;
