import React from "react";
import { Box, Container, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import { FaFacebookSquare, FaLocationArrow } from "react-icons/fa";
import PageTitle from "../components/PageTitle";

function Contact() {
    return (
        <Box py={6} bg={useColorModeValue("light.bg.primary", "dark.bg.primary")}>
            <Container maxW="container.lg">
                <PageTitle title="Contact Us" />
                <Flex direction="column" mb={6} pb={6} borderBottom={1} borderStyle={"solid"} borderColor={useColorModeValue("light.border.primary", "dark.border.primary")}>
                    <Flex direction="row" alignItems="center">
                        <FaFacebookSquare size={24} style={{ marginRight: '1rem' }} />
                        <a href="https://www.facebook.com/tilak.shastri">Tilak Shastri</a>
                    </Flex>
                    <Box mt={3}>
                        <EmailIcon w={6} h={6} mr={4} />
                        <a href="mailto:tilakje@gmail.com">tilakje@gmail.com</a>
                    </Box>
                    <Flex direction="row" alignItems="center" mt={3}>
                        <PhoneIcon w={6} h={6} mr={4} />
                        <Flex direction="column">
                            <a href="tel:+447947118464">+44 79471 18464 (UK)</a>
                            <a href="tel:+918668648304">+91 86686 48304 (INDIA)</a>
                            <a href="tel:+9779814079069">+977 98140 79069 (NEPAL)</a>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex align={{ base: "start", md: "center" }} direction={{ base: "column", md: "row" }}>
                    <Flex align={"center"} mr={{ base: 0, md: 6 }} mb={{ base: 6, md: 0 }}>
                        <Icon w={6} h={6} mr={4} as={FaLocationArrow} />
                        <Box>
                            <Text whiteSpace="nowrap">श्री कृष्ण प्रणामी सेवा कुञ्ज</Text>
                            <Text whiteSpace="nowrap">धार्मिक पर्यटन क्षेत्र</Text>
                            <Text whiteSpace="nowrap">बरबोटे-5, इलाम</Text>
                        </Box>
                    </Flex>
                </Flex>
            </Container>
        </Box>
    );
}

export default Contact;
