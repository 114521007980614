import { ChevronDownIcon, ChevronRightIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
    Box,
    Collapse,
    Container,
    Flex,
    Icon,
    IconButton,
    Link,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Stack,
    Text,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";
import { useLocation } from "react-router";
import pagesData from "../assets/json/pages.json";
import ColorModeSwitcher from "./ColorModeSwitcher";

function NavBar() {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Box
            bg={useColorModeValue("light.bg.highlighted", "dark.bg.highlighted")}
            color={useColorModeValue("light.text.primary", "dark.text.primary")}
            position="sticky"
            top={0}
            zIndex={10}
        >
            <Container maxW="container.lg">
                <Flex
                    minH={"60px"}
                    align={"center"}
                    justify={"space-between"}
                >
                    <Flex
                        justify={{ base: "center", md: "start" }}
                        align={"center"}
                    >
                        <Link
                            href={pagesData[0].href}
                        >
                            Sewakunj
                        </Link>
                        <Flex
                            display={{ base: "none", md: "flex" }}
                            ml={10}
                        >
                            <DesktopNav />
                        </Flex>
                    </Flex>

                    <Flex>
                        <ColorModeSwitcher />
                        <Flex display={{ base: "flex", md: "none" }}>
                            <IconButton
                                onClick={onToggle}
                                icon={
                                    isOpen ? (
                                        <CloseIcon
                                            w={3}
                                            h={3}
                                        />
                                    ) : (
                                        <HamburgerIcon
                                            w={5}
                                            h={5}
                                        />
                                    )
                                }
                                variant={"ghost"}
                                aria-label={"Toggle Navigation"}
                            />
                        </Flex>
                    </Flex>
                </Flex>

                <Collapse
                    in={isOpen}
                    animateOpacity
                    style={{ borderBottom: "1px solid light.bg.highlighted" }}
                >
                    <MobileNav />
                </Collapse>
            </Container>
        </Box>
    );
}

const DesktopNav = () => {
    const location = useLocation();
    const linkColor = useColorModeValue("light.text.primary", "dark.text.primary");
    const linkHoverColor = useColorModeValue("light.text.hover", "dark.text.hover");
    const popoverContentBgColor = useColorModeValue("light.bg.highlighted", "dark.bg.highlighted");

    return (
        <Stack
            direction={"row"}
            spacing={4}
        >
            {pagesData.map((navItem) => (
                <Box key={navItem.label}>
                    <Popover
                        trigger={"hover"}
                        placement={"bottom-start"}
                    >
                        <PopoverTrigger>
                            <Link
                                p={2}
                                href={navItem.href ?? "#"}
                                fontSize={"md"}
                                color={linkColor}
                                _hover={{
                                    textDecoration: "none",
                                    color: linkHoverColor,
                                }}
                                fontWeight={navItem.href === location.pathname ? 700 : 400}
                            >
                                {navItem.label}
                            </Link>
                        </PopoverTrigger>

                        {navItem.children && (
                            <PopoverContent
                                border={0}
                                boxShadow={"xl"}
                                bg={popoverContentBgColor}
                                p={4}
                                rounded={"xl"}
                                minW={"sm"}
                            >
                                <Stack>
                                    {navItem.children.map((child) => (
                                        <DesktopSubNav
                                            key={child.label}
                                            {...child}
                                        />
                                    ))}
                                </Stack>
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))}
        </Stack>
    );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
    return (
        <Link
            href={href}
            role={"group"}
            display={"block"}
            p={2}
            rounded={"md"}
            _hover={{ bg: useColorModeValue("light.bg.hover", "dark.bg.hover") }}
        >
            <Stack
                direction={"row"}
                align={"center"}
            >
                <Box>
                    <Text
                        transition={"all .3s ease"}
                        _groupHover={{ color: "teal.500" }}
                    >
                        {label}
                    </Text>
                    <Text fontSize={"sm"}>{subLabel}</Text>
                </Box>
                <Flex
                    transition={"all .3s ease"}
                    transform={"translateX(-10px)"}
                    opacity={0}
                    _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
                    justify={"flex-end"}
                    align={"center"}
                    flex={1}
                >
                    <Icon
                        color={"teal.500"}
                        w={5}
                        h={5}
                        as={ChevronRightIcon}
                    />
                </Flex>
            </Stack>
        </Link>
    );
};

const MobileNav = () => {
    return (
        <Stack
            bg={useColorModeValue("light.bg.highlighted", "dark.bg.highlighted")}
            py={4}
            display={{ md: "none" }}
        >
            {pagesData.map((navItem) => (
                <MobileNavItem
                    key={navItem.label}
                    {...navItem}
                />
            ))}
        </Stack>
    );
};

const MobileNavItem = ({ label, children, href }) => {
    const location = useLocation();
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack
            spacing={4}
            onClick={children && onToggle}
        >
            <Flex
                py={2}
                as={Link}
                href={href ?? "#"}
                justify={"space-between"}
                align={"center"}
                _hover={{
                    textDecoration: "none",
                }}
            >
                <Text
                    color={useColorModeValue("gray.600", "gray.200")}
                    fontWeight={href === location.pathname ? 700 : 400}
                >
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={"all .25s ease-in-out"}
                        transform={isOpen ? "rotate(180deg)" : ""}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse
                in={isOpen}
                animateOpacity
                style={{ marginTop: 0 }}
            >
                <Stack
                    pl={4}
                    borderLeft={1}
                    borderStyle={"solid"}
                    borderColor={useColorModeValue("gray.200", "gray.700")}
                    align={"start"}
                >
                    {children &&
                        children.map((child) => (
                            <Link
                                key={child.label}
                                pt={2}
                                href={child.href}
                            >
                                {child.label}
                            </Link>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};

export default NavBar;
